<template>
  <section>
    <div class="login-page" style="min-height: 496.781px;">
      <div class="login-box">
        <div class="card">
          <div class="card-body login-card-body">
            <h5 class="login-box-msg"><b>ระบบบริหารจัดการกลุ่มธีระการแว่น</b></h5>
            <img :src="imglink('logo_.png')"  class="img-fluid" >

            <form @submit.prevent="loginUser" >
              <div class="input-group mb-3">
                <input
                    v-model.trim="$v.username.$model"
                    type="text"
                    class="form-control"
                    placeholder="Username"
                    :class="{ 'is-invalid':$v.username.$error }"
                />
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-user"></span>
                  </div>
                </div>
<!--                <div v-if="!$v.username.required || !$v.username.minLength" class="invalid-feedback">First Name is required / Name must have at least {{$v.username.$params.minLength.min}} letters.</div>-->
                <span v-if="!$v.username.required " class="invalid-feedback">First Name is required</span>
<!--                <span v-if="!$v.username.alphaNumAndDotValidator || !$v.username.alpha" class="invalid-feedback">incorrect format</span>-->
              </div>
              <div class="input-group mb-3">
                <input
                    v-model.trim="$v.password.$model"
                    type="password"
                    class="form-control"
                    placeholder="Password"
                    :class="{ 'is-invalid':$v.password.$error }"
                >
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-lock"></span>
                  </div>
                </div>
                <span v-if="!$v.password.required " class="invalid-feedback">password is required</span>
                <span v-if="!$v.password.minLength" class="invalid-feedback">password Name must have at least {{$v.password.$params.minLength.min}}</span>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <button type="submit" class="btn btn-block btn-primary">Sign In</button>
                </div>
              </div>
            </form>

<!--            <div class="social-auth-links text-center mb-3">-->
<!--              <p>- OR -</p>-->
<!--            </div>-->
            <!-- /.social-auth-links -->

            <p class="mb-1" style="height: 40px">
              <router-link to="/resetPassword" class="nav-link text-center"></router-link>
            </p>
<!--            <p class="mb-0">-->
<!--              <router-link to="/register" class="nav-link text-center"></router-link>-->
<!--            </p>-->
            <div class="social-auth-links text-center mb-3">
              <p>- กรุณา Login เพื่อเข้าสู่ระบบ -</p>
            </div>
          </div>
          <!-- /.login-card-body -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapActions} from 'vuex'
import router from "@/router";
import { required, minLength  } from "vuelidate/lib/validators";
export default {
  name: 'login',
  data() {
    return {
      username: "",
      password: "",
    };
  },
  validations: {
    username: {
      required,
      minLength: minLength(3),
      // alphaNumAndDotValidator,
      // alpha
    },
    password: {
      required,
      minLength: minLength(8),

    }
  },
  computed: {
  },
  methods: {
    ...mapActions(['login']),
    async loginUser(){
      this.$v.$touch()
      if (this.$v.$pending || this.$v.$error) return;

        let user = {
          username: this.username,
          password: this.password
        };
         await this.login(user)
              .then(res => {
                if (res.status === 200 && res.data.success === true) {
                  router.push('/');
                }
              }).catch(() => {
            // console.log('123')
            this.$swal('something wrong', this.$store.getters.error, 'error');
          });
      // }
    },
    imglink: function(data) {
      return require("@/assets/"+data);
    }
  }
}
</script>

<style scoped>
.card{
  border-radius:25px;
  width: 100%;
}
.card-body{
  border-radius:25px;
}

</style>